export default {
    cart: 'Себет',
    addToCart: 'Себетке салу',
    addedToCart: 'Себетке салынды',
    added: 'Салынды',
    addLoading: 'Салынуда...',
    freeDelivery: '50 000 ₸ бастап тапсырыстар тегін жеткізіледі',
    chooseSize: 'Өлшемді таңдау',
    sizeChart: 'Өлшемдер кестесі',
    availableColors: 'Қолжетімді түстер',
    chooseYourSize: 'Өз өлшеміңді таңда',
    onWarehouse: 'Қоймада қалғаны',
    gallery: 'Галерея',
    description: 'Сипаты',
    care: 'Күтім',
    aboutModel: 'Модель туралы',
    modelColor: 'Модельдің түсі',
    modelArticle: 'Модельдің нөмірі',
    careRecommendation: 'Жуу бойынша ұсынымдар',
    extraCareRecommendation: 'Күтім бойынша қосымша ақпарат',
    color: 'Түсі',
    size: 'Өлшемі',
    count: 'Саны',
    countShort: 'Саны',
    withPDV: '(ҚҚС қосқанда)',
    totalSum: 'Жалпы сома',
    totalProduct: 'Барлық тауар',
    costProduct: 'Тауардың құны',
    halykPartTitle: 'Бөліп төлеуге сатып ал ',
    halykPartTitleWithTerm: '3 айға бөліп төлеуге сатып ал ',
    halykMore: 'Толығырақ білу',
    bnplTitle: '0-0-4 бөліп төлеу',
    bnplMonth: 'ай',
    bnplReadMore: 'Толығырақ',
    infoRow: {
        row1: 'британдық өлшем көрсетілген, өлшемдер кестесімен салыстыр',
        row2: '50 000 ₸ басталатын сомаға тапсырыс тегін жеткізіледі',
        row3: 'Қазпошта, DPD және DHL қызметтерімен Қазақстан Республикасы бойынша жеткізу',
        row4: 'Тауарды қайтаруға 60 күн',
    },
    sizeEmpty: {
        title: 'Тауар толықтай сатылып кетті',
        text: 'Өкінішке қарай, қазір бұл тауар қолымызда жоқ.',
    }
}
